import { FaLaravel, FaNodeJs, FaReact, FaSymfony } from "react-icons/fa";
import {SiFlutter, SiNumpy, SiPandas, SiTensorflow,SiScikitlearn, SiSpring} from "react-icons/si"
import {FcScatterPlot} from "react-icons/fc"
import {AiFillBoxPlot} from "react-icons/ai"
export default {
    node : FaNodeJs,
    reactjs : FaReact,
    symfony: FaSymfony,
    laravel : FaLaravel,
    flutter : SiFlutter,
    reactnative : FaReact,
    numpy : SiNumpy,
    pandas: SiPandas,
    tensorflow : SiTensorflow,
    matplotlib : FcScatterPlot,
    sklearn : SiScikitlearn,
    seaborn: AiFillBoxPlot,
    springboot : SiSpring

}