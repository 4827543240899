import PageHelmet from "../../component/common/Helmet";
import Breadcrumb from "../../elements/common/Breadcrumb";

import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from "../../component/header/Header";
import FooterTwo from "../../component/footer/FooterTwo";
import fr from "../../assets/json/i12n/myCv/fr.json"
import TechCard from "../compents/TechCard";

import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import ExpMission from "../compents/ExpMission";

const Experiences = () => {
    const [experiences, setExperiences] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const querySnapshot = await getDocs(collection(db, "experiences"));
            const docs = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            setExperiences(docs.reverse())
        };

        fetchData();
    }, []);

    return (
        <div className="active-dark">
            <React.Fragment>
                <PageHelmet pageTitle='Experiences' />

                <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" />
                {/* Start Breadcrump Area */}
                <Breadcrumb title={'Experiences'} />
                {/* End Breadcrump Area */}


                {/* Start Blog Area */}
                <div className="rn-blog-area ptb--120 bg_color--1">
                    {
                        experiences.map((exp) => (

                            <div className="container mb-5">
                                <h2>{exp.title}</h2>
                                <h3>{exp.company}</h3>
                                <h4>{exp.startDate} - {exp.endDate}</h4>
                                <h4>{exp.location}</h4>
                                <h3 className="mt-5">Technologies et outils</h3>
                                <div className="col-md-8">
                                    <div className="d-flex align-content-end  flex-wrap">
                                        {exp.technologies.map((sk) => (
                                            <div className="">
                                                <TechCard tech={sk} />
                                            </div>
                                        ))}
                                    </div>
                                </div>

                                <h3 className="mt-5">Projet</h3>
                                <h5>{exp.project}</h5>

                                <h3 className="mt-5">Context</h3>
                                <h5>{exp.context}</h5>

                                <h3 className="mt-5">Missions</h3>

                                <div className="col-md-8"> 
                                    {
                                        exp.assignments.map((ass) => (
                                            <ExpMission mission={ass}/>
                                        ))
                                    }

                                </div>

                                <h3 className="mt-5">Résultats obetnus</h3>
                                <div className="col-md-8">
                                    <ul>
                                        {exp.results.map((it) => <li className="fs-5 text-white">{it}</li>)}
                                    </ul>
                                </div>

                                <hr className="text-white bg-white h-4" />
                            </div>))
                    }
                </div>
                {/* End Blog Area */}

                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}
                {/* <CallAction/> */}
                <FooterTwo />

            </React.Fragment></div>
    )
}

export default Experiences;
