import fr from "../../assets/json/i12n/myCv/fr.json"
import CompKey from "../compents/CompKey2"
import React, { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';

const CompetencesList = () => {
    const [skills, setSkills] = useState([]);

    useEffect(() => {
      const fetchData = async () => {
        const querySnapshot = await getDocs(collection(db, "skills"));
        setSkills(querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id })));
      };
  
      fetchData();
    }, []);

    return (
      
            <div id="home" className="fix ">
                <div className="slider-wrapper row justify-content-center">
                        <span className="text-center text-muted">Vous pouvez cliquer sur un élément</span>
                    {
                        skills.map((domain) => {
                            return (
                                <div id="service" className="fix col-md-6" key={domain.id}>
                                    <div className="service-area creative-service-wrapper ptb--120 bg_c2olor--5" >
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="section-title text-center service-style--3 mb--30 mb_sm--0">
                                                        <h3 className="title">{domain.domain}</h3>
                                                        {/* <p>{fr.topFramewords.description}</p> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row creative-service">
                                                <div className="col-lg-12">
                                                    <div className="d-flex align-content-end justify-content-center flex-wrap">
                                                    {domain.skills.map((sk)=>(
                                                        <div className="">
                                                            <CompKey comp={sk}/>
                                                        </div>
                                                    ))}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }

                </div>
            </div>
       
    )
}

export default CompetencesList