import { useState } from "react"
import StarRatings from "react-star-ratings"
const CompKey = ({ comp }) => {
    return (
        <div className="bg-success rounded-pill m-2 p-1 px-3 opacity-50 fw-bold text-white text-center">
            <div className="">
                {comp}
            </div>

        </div>
    )
}

export default CompKey