import { useState } from "react"
const ExpMission = ({ mission }) => {
    const [showStars, setShowStars] = useState(false)
    return (
        <div style={{ cursor: "pointer" }} onClick={() => setShowStars(!showStars)}>
            <h5>{mission.title}</h5>
            {showStars &&
                <ul>
                    {mission.details.map((it) => <li className="fs-5 text-white">{it}</li>)}
                </ul>
            }
            {!showStars && <>
                <ul>
                    <li className="fs-5 text-white">{mission.details[0]}</li>
                </ul>
                <div className=" mx-2 mb-3  px-3 fw-bold text-white" >
                    Voir plus de détails...

                </div>
            </>

            }
        </div>
    )
}

export default ExpMission