import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {

    apiKey: "AIzaSyAkAqU4gDM8QXci4sVSjgY5qcFJw3JLq_M",
  
    authDomain: "portfolio-90aee.firebaseapp.com",
  
    projectId: "portfolio-90aee",
  
    storageBucket: "portfolio-90aee.appspot.com",
  
    messagingSenderId: "100303589895",
  
    appId: "1:100303589895:web:14af9e3b0efb04e04790a2"
  
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };
