import React, { Component } from "react";
import { FiCast, FiLayers, FiUsers, FiMonitor, FiPhone, FiServer, FiTablet, FiRotateCcw, FiRotateCw, FiCornerUpRight, FiLock, FiDatabase, FiSidebar } from "react-icons/fi";
import { FaReact, FaNode, FaNodeJs, FaSymfony, FaLaravel } from "react-icons/fa"
import StarRatings from "react-star-ratings";
import fr from "../../assets/json/i12n/myCv/fr.json"
import listIcons from "../../component/icons/list"
// const ServiceList = [
//     {
//         icon: <FiCast />,
//         title: 'Business Stratagy',
//         description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
//     },
//     {
//         icon: <FiLayers />,
//         title: 'Website Development',
//         description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
//     },
//     {
//         icon: <FiUsers />,
//         title: 'Marketing & Reporting',
//         description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
//     },
//     { 
//         icon: <FiMonitor />,
//         title: 'Mobile App Development',
//         description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
//     },
//     {
//         icon: <FiUsers />,
//         title: 'Marketing & Reporting',
//         description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
//     },
//     { 
//         icon: <FiMonitor />,
//         title: 'Mobile App Development',
//         description: 'I throw myself down among the tall grass by the stream as I lie close to the earth.'
//     }
// ]


const ServiceList = [
    {
        icon: <FaReact size={100} />,
        title: 'Développement Web',
        description: 'Pour restée visible, log, forum, institutionnel, communautaire…'
    },
    {
        icon: <FaNode />,
        title: 'Développement Mobile',
        description: 'Vous l’auriez compris, avec les applications mobiles, tout est mieux. Nous vous proposons un travail sur mesure.'
    },
    {
        icon: <FaNodeJs />,
        title: 'Développement Desktop',
        description: 'Voulez-vous une version bureautique pour votre application ? Windows, Linux ou Mac ? On gère tout pour vous .'
    },
    {
        icon: <FaLaravel />,
        title: 'Intelligence Artificielle',
        description: 'Progresser votre maturité en IA pour booster votre croissance. Comprendre vos données, prendre des décisions à travers des modèles d’IA.'
    },
    {
        icon: <FaSymfony size={100} />,
        title: 'Sécurité informatique',
        description: 'Vous n’allez quand même pas laisser vous équipements exposés ! De l’installation jusqu’au monitoring on est là !'
    },
    {
        icon: <FiCornerUpRight />,
        title: 'Réorientation',
        description: 'Vous souhaitez devenir programmeur, peut importe votre parcours précédent, nous sommes là pour vous aider. '
    },
]


class ServiceThree extends Component {
    render() {
        const { column } = this.props;
       

        return (
            <React.Fragment>
                <div className="row items-center justify-center">
                    {fr.topFramewords.frameworks.map((val, i) => {
                        const Cur = listIcons[val.icon];
                       return  <div className={`${column}`} key={i}>
                           
                                <div className="service service__style--2 text-center">
                                    <div className="icon">
                                        <Cur size={100}/>
                                    </div>
                                    <h3>{val.name}</h3>
                                    <div>
                                        <StarRatings starDimension="25px" rating={val.stars} starRatedColor="orange"/>
                                    </div>
                                    <div className="content mt-2">
                                        <h4 className="title">{val.categody}</h4>
                                        <p>_ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ _ </p>
                                    </div>
                                </div>
                           
                        </div>
    })}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
